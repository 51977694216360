import React from "react";

import Navbar from "./Navbar";

function Faculty() {
  return (
    <div style={{ backgroundColor: "#b8c0e7" }}>
      <Navbar />
      <div>
        <h1
          style={{ textAlign: "center", marginTop: "65px", paddingTop: "10px" }}
        >
          Pharmacy (B.Pharma)
        </h1>
      </div>
      <iframe
        src="/facultyList.pdf" // Update this path to the actual location of your PDF file
        width="100%"
        height="800px"
        style={{ border: "none" }}
        title="Faculty PDF"
      ></iframe>
    </div>
  );
}

export default Faculty;
