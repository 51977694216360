import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AntiRaggingHelpDesk from './components/AntiRagggingHelpDesk';
import CourseDetails from './components/CourseDetails';
import Faculty from './components/Faculty';
import Board from './pages/Board';
import Contact from './pages/Contact';
import Homepage from './pages/Homepage'
import Societies from './pages/Societies';

import './App.css';

function App() {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/*" element={<h1>Not Found 404</h1>} />
        <Route path="/board" element={<Board />} />
        <Route path="/societies" element={<Societies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/course-details/:id" element={<CourseDetails />} /> {/* New Route */}
        <Route path="/anti-ragging-help" element={<AntiRaggingHelpDesk />} />
        <Route path="/faculty" element={<Faculty />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;